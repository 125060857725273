/* This File is for {purpose} */

<template>
  <v-layout wrap justify-center pa-8>
    <v-flex xs12 sm6 lg3>
      <v-form ref="form" lazy-validation>
        <v-layout wrap justify-center>
          <v-flex xs12 text-center>
            <img src="@/assets/logo.svg" height="50px" contain />
          </v-flex>
          <v-flex xs12 py-12></v-flex>
          <v-flex xs12>
            <AppNavButton :route="{ name: 'login' }" outlined color="#C0CBE0">
              <template #content>
                <v-icon color="#000000" size="20px">mdi-arrow-left</v-icon>
              </template>
            </AppNavButton>
          </v-flex>
          <V-flex xs12 pt-8>
            <span class="title-main">
              {{ $t("pages.reset_password.title") }}
            </span>
            <br />
            <p class="subtitle-2">
              {{ $t("pages.reset_password.sub_heading") }}
            </p>
          </V-flex>
          <v-flex xs12 pt-8>
            <p class="input-label">{{ $t("pages.reset_password.new_pswd") }}</p>
            <v-text-field
              placeholder="****************"
              dense
              outlined
              v-model="password"
              :rules="[RULES.REQUIRED]"
              :type="viewPassword ? 'text' : 'password'"
              :append-icon="viewPassword ? `mdi-eye` : 'mdi-eye-off'"
              @click:append="viewPassword = !viewPassword"
            />
          </v-flex>
          <v-flex xs12 pt-4>
            <p class="input-label">{{ $t("form.confirm_password") }}</p>
            <v-text-field
              placeholder="****************"
              dense
              outlined
              v-model="password_confirmation"
              :rules="[RULES.REQUIRED, RULES.EQUALS(password)]"
              :type="viewConfirmPassword ? 'text' : 'password'"
              :append-icon="viewConfirmPassword ? `mdi-eye` : 'mdi-eye-off'"
              @click:append="viewConfirmPassword = !viewConfirmPassword"
            />
          </v-flex>
          <v-flex xs12 pt-12> </v-flex>
        </v-layout>
      </v-form>
      <v-layout wrap justify-start>
        <v-flex xs12 pt-12>
          <AppButton
            block
            custom-class="py-5"
            label="pages.reset_password.title"
            @submit="resetPassword"
          />
        </v-flex>
      </v-layout>
    </v-flex>
    <SuccessConfirmation
      v-model="showSuccess"
      title="pages.settings.password_changed"
      @submit="success"
    />
  </v-layout>
</template>

<script>
import { RULES } from "@/libs/helper/components/rules";

export default {
  name: "ResetPassword",
  data: () => ({
    RULES,
    showSuccess: false,
    password: null,
    password_confirmation: null,
    viewPassword: false,
    viewConfirmPassword: false,
  }),
  methods: {
    success() {
      this.$router.push({ name: "login" });
    },
    toggleSuccess() {
      this.showSuccess = !this.showSuccess;
    },
    resetPassword() {
      if (!this.$refs.form.validate()) return;
      const expires = this.$route.query.url.substring(
        this.$route.query.url.indexOf("expires=") + 8
      );
      const data = {
        expires: expires,
        id: this.$route.query.id,
        old_sign: this.$route.query.old_sign,
        signature: this.$route.query.signature,
        password: this.password,
        password_confirmation: this.password_confirmation,
      };
      console.log(data);
      this.$store
        .dispatch("app/resetPassword", data)
        .then((res) => {
          console.log(res);
          this.$refs.form.reset();
          this.toggleSuccess();
        })
        .catch((err) => {
          console.log(err);
          this.message = err.response.data.message;
          this.snackbarColor = "error";
          this.showSnackbar = true;
        });
    },
  },
};
</script>

<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: #c0cbe0 !important;
}

.v-text-field--outlined::v-deep input::placeholder,
.v-text-field--outlined::v-deep textarea::placeholder {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.02em;
  color: #9fadc8;
}

.v-input ::v-deep input,
.v-input ::v-deep textarea,
.v-input ::v-deep .v-select__selection {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.02em;
  color: #081734;
}

.v-input ::v-deep label {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.02em;
  color: #081734;
}
</style>
