/* This File is for form validation rules */
import i18n from "@/plugins/i18n";

const messages = {
  required: i18n.t("validations.required"),
  email: i18n.t("validations.email"),
  date: i18n.t("validations.date"),
  valueMatch: i18n.t("validations.valueMatch"),
  value: i18n.t("validations.value"),
  year: i18n.t("validations.year"),
  time: i18n.t("validations.time"),
  minChars: function (val) {
    return i18n.t("validations.minChars", val);
  },
  maxChars: function (val) {
    return i18n.t("validations.maxChars", val);
  },
  maxFileSize: function (val) {
    return i18n.t("validations.maxFileSize", val);
  },
};

export const _required = (val) => {
  return (val && !!val && val.length !== 0) || messages.required;
};

export const _email = (val) => {
  if (!val) return true;
  const pattern =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return pattern.test(val) || messages.email;
};

export const _date = (val) => {
  const pattern = /^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[012])-\d{4}$/; //DD-MM-YYYY
  if (!val) return true;
  return pattern.test(val) || messages.date;
};

export const _maxFileSize = function (val = 300) {
  return function (value) {
    if (!value) return true;
    return value?.size < val * 1024 || messages.maxFileSize(val);
  };
};

const _minChars = function (val) {
  return function (value) {
    return value?.length >= val || messages.minChars(val);
  };
};

const _maxChars = function (val) {
  return function (value) {
    return value?.length <= val || messages.maxChars(val);
  };
};
const _equals = function (val) {
  return function (value) {
    return value === val || messages.valueMatch;
  };
};

export const _number = (val) => {
  const pattern = /^[0-9]+$/;
  if (!val) return true;
  return pattern.test(val) || messages.value;
};

export const _floatingNumber = (val) => {
  const pattern = /^[0-9]*\.?[0-9]*$/;
  if (!val) return true;
  return pattern.test(val) || messages.value;
};
export const _phoneNumber = (val) => {
  const pattern = /^([0-9]|#|\+|\*)+$/;
  if (!val) return true;
  return pattern.test(val) || messages.value;
};
export const _year = (val) => {
  const pattern = /^[12][0-9]{3}$/;
  if (!val) return true;
  return pattern.test(val) || messages.year;
};

export const _wtime = (val) => {
  const pattern = /^([01]\d|2[0-3]):?([0-5]\d)$/;
  if (!val) return true;
  return pattern.test(val) || messages.time;
};

export const RULES = {
  REQUIRED: _required,
  EMAIL: _email,
  DATE: _date,
  MAX_FILE_SIZE: _maxFileSize,
  MIN_CHARS: _minChars,
  MAX_CHARS: _maxChars,
  NUMBER: _number,
  FLOAT: _floatingNumber,
  PHONE_NUMBER: _phoneNumber,
  YEAR: _year,
  EQUALS: _equals,
  WTIME: _wtime,
};
export default RULES;
