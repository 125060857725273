var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-layout",
    { attrs: { wrap: "", "justify-center": "", "pa-8": "" } },
    [
      _c(
        "v-flex",
        { attrs: { xs12: "", sm6: "", lg3: "" } },
        [
          _c(
            "v-form",
            { ref: "form", attrs: { "lazy-validation": "" } },
            [
              _c(
                "v-layout",
                { attrs: { wrap: "", "justify-center": "" } },
                [
                  _c("v-flex", { attrs: { xs12: "", "text-center": "" } }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/logo.svg"),
                        height: "50px",
                        contain: "",
                      },
                    }),
                  ]),
                  _c("v-flex", { attrs: { xs12: "", "py-12": "" } }),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("AppNavButton", {
                        attrs: {
                          route: { name: "login" },
                          outlined: "",
                          color: "#C0CBE0",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "content",
                            fn: function () {
                              return [
                                _c(
                                  "v-icon",
                                  { attrs: { color: "#000000", size: "20px" } },
                                  [_vm._v("mdi-arrow-left")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c("V-flex", { attrs: { xs12: "", "pt-8": "" } }, [
                    _c("span", { staticClass: "title-main" }, [
                      _vm._v(
                        " " + _vm._s(_vm.$t("pages.reset_password.title")) + " "
                      ),
                    ]),
                    _c("br"),
                    _c("p", { staticClass: "subtitle-2" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("pages.reset_password.sub_heading")) +
                          " "
                      ),
                    ]),
                  ]),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", "pt-8": "" } },
                    [
                      _c("p", { staticClass: "input-label" }, [
                        _vm._v(_vm._s(_vm.$t("pages.reset_password.new_pswd"))),
                      ]),
                      _c("v-text-field", {
                        attrs: {
                          placeholder: "****************",
                          dense: "",
                          outlined: "",
                          rules: [_vm.RULES.REQUIRED],
                          type: _vm.viewPassword ? "text" : "password",
                          "append-icon": _vm.viewPassword
                            ? `mdi-eye`
                            : "mdi-eye-off",
                        },
                        on: {
                          "click:append": function ($event) {
                            _vm.viewPassword = !_vm.viewPassword
                          },
                        },
                        model: {
                          value: _vm.password,
                          callback: function ($$v) {
                            _vm.password = $$v
                          },
                          expression: "password",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", "pt-4": "" } },
                    [
                      _c("p", { staticClass: "input-label" }, [
                        _vm._v(_vm._s(_vm.$t("form.confirm_password"))),
                      ]),
                      _c("v-text-field", {
                        attrs: {
                          placeholder: "****************",
                          dense: "",
                          outlined: "",
                          rules: [
                            _vm.RULES.REQUIRED,
                            _vm.RULES.EQUALS(_vm.password),
                          ],
                          type: _vm.viewConfirmPassword ? "text" : "password",
                          "append-icon": _vm.viewConfirmPassword
                            ? `mdi-eye`
                            : "mdi-eye-off",
                        },
                        on: {
                          "click:append": function ($event) {
                            _vm.viewConfirmPassword = !_vm.viewConfirmPassword
                          },
                        },
                        model: {
                          value: _vm.password_confirmation,
                          callback: function ($$v) {
                            _vm.password_confirmation = $$v
                          },
                          expression: "password_confirmation",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("v-flex", { attrs: { xs12: "", "pt-12": "" } }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-layout",
            { attrs: { wrap: "", "justify-start": "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "", "pt-12": "" } },
                [
                  _c("AppButton", {
                    attrs: {
                      block: "",
                      "custom-class": "py-5",
                      label: "pages.reset_password.title",
                    },
                    on: { submit: _vm.resetPassword },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("SuccessConfirmation", {
        attrs: { title: "pages.settings.password_changed" },
        on: { submit: _vm.success },
        model: {
          value: _vm.showSuccess,
          callback: function ($$v) {
            _vm.showSuccess = $$v
          },
          expression: "showSuccess",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }